import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Menu from "../components/docs/menu"

import Template from "../templates/post"

import { H3 } from "../styles/global"
import { DocSection } from "../styles/mdDocs"

import ChevLeft from "../assets/svg/left-arrow.svg"

const SectionWrapper = ({ children }) => (
  <section className="mt-20 md:mt-20 md:mb-20 deconta-docs wrapper block md:flex pl-4 pr-4 md:pl-10 md:pr-10">
    {children}
  </section>
)

const DocSectionWrapper = ({ children }) => (
  <SectionStyle className="md:pl-10">{children}</SectionStyle>
)
const SectionStyle = styled.section`
  & .icon {
    fill: var(--textSd) !important;
    top: 8.5px;
  }
`

const Docs = () => {
  return (
    <Layout page={"docs"}>
      <SectionWrapper className="">
        <div className="block md:flex">
          <div className="sidebar w-full pb-5 md:pb-0 md:w-3/12">
            <Menu page="endpoints" />
          </div>
          <div className="content md:w-9/12">
            <DocSectionWrapper>
              <section>
                <div className="relative pl-6 flex">
                  <button onClick={() => window.history.back()}>
                    <ChevLeft className="h-3 absolute left-0 icon" />
                  </button>
                  <H3 id="endpoints">API Endpoints</H3>
                </div>

                <DocSection link="api-endpoint-latest-measurement">
                  <Template postPath="/docs/endpoint-latest-measurement" />
                </DocSection>

                <DocSection link="api-endpoint-latest-error">
                  <Template postPath="/docs/endpoint-latest-error" />
                </DocSection>
                
                <DocSection link="api-endpoint-control">
                  <Template postPath="/docs/endpoint-control" />
                </DocSection>

              </section>
            </DocSectionWrapper>
          </div>
        </div>
      </SectionWrapper>
    </Layout>
  )
}

export default Docs
